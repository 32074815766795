import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import AdjustableBase from '../../src/Icons/AdjustableBase';
import AdjustableBaseFull from '../../src/Icons/AdjustableBaseFull';
import BedSets from '../../src/Icons/BedSets';
import BoxSpring from '../../src/Icons/BoxSpring';
import MattressWithBoxSpring from '../../src/Icons/MattressBoxSpring';
import PlatformFrame from '../../src/Icons/PlatformFrame';
import StorageBelowBed from '../../src/Icons/StorageBelowBed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <AdjustableBase width={30} height={30} />\n  <AdjustableBaseFull width={30} height={30} />\n  <BedSets width={30} height={30} />\n  <BoxSpring width={30} height={30} />\n  <MattressWithBoxSpring width={30} height={30} />\n  <PlatformFrame width={30} height={30} />\n  <StorageBelowBed width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      AdjustableBase,
      AdjustableBaseFull,
      BedSets,
      BoxSpring,
      MattressWithBoxSpring,
      PlatformFrame,
      StorageBelowBed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <AdjustableBase width={30} height={30} mdxType="AdjustableBase" />
    <AdjustableBaseFull width={30} height={30} mdxType="AdjustableBaseFull" />
    <BedSets width={30} height={30} mdxType="BedSets" />
    <BoxSpring width={30} height={30} mdxType="BoxSpring" />
    <MattressWithBoxSpring width={30} height={30} mdxType="MattressWithBoxSpring" />
    <PlatformFrame width={30} height={30} mdxType="PlatformFrame" />
    <StorageBelowBed width={30} height={30} mdxType="StorageBelowBed" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      